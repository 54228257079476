import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import { ADMIN_ROUTE_PATH } from '@/administration/constants/adminRoutePaths';
import { useIsManagementContext } from '@/management/context/context';

export const useLearningContentHref = (): {
  adminJourneyLearningInsightsHref: (journeyId: number, contentURN?: string) => string;
  adminLearningInsightsHref: (contentURN?: string) => string;
  managerJourneyLearningInsightsHref: (journeyId: number, contentURN?: string) => string;
  managerLearningInsightsHref: (contentURN?: string) => string;

  adminJourneyLearningActivityInsightsHref: (journeyId: number, contentURN?: string, activityId?: number) => string;
  adminLearningActivityInsightsHref: (contentURN?: string, activityId?: number) => string;
  managerJourneyLearningActivityInsightsHref: (journeyId: number, contentURN?: string, activityId?: number) => string;
  managerLearningActivityInsightsHref: (contentURN?: string, activityId?: number) => string;
} => {
  return {
    adminJourneyLearningInsightsHref: (journeyId: number, contentURN?: string) =>
      `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/learning/${contentURN}/insights`,
    adminLearningInsightsHref: (contentURN?: string) => `${ADMIN_ROUTE_PATH.CATALOG}/learning/${contentURN}/insights`,
    managerJourneyLearningInsightsHref: (journeyId: number, contentURN?: string) =>
      `/management/analyze/journeys/${journeyId}/learning/${contentURN}/insights`,
    managerLearningInsightsHref: (contentURN?: string) => `/management/analyze/content/${contentURN}/insights`,

    adminJourneyLearningActivityInsightsHref: (journeyId: number, contentURN?: string, activityId?: number) =>
      `${ADMIN_ROUTE_PATH.JOURNEYS}/${journeyId}/learning/${contentURN}/activity/${activityId}/insights`,
    adminLearningActivityInsightsHref: (contentURN?: string, activityId?: number) =>
      `${ADMIN_ROUTE_PATH.CATALOG}/learning/${contentURN}/activity/${activityId}/insights`,
    managerJourneyLearningActivityInsightsHref: (journeyId: number, contentURN?: string, activityId?: number) =>
      `/management/analyze/journeys/${journeyId}/learning/${contentURN}/activity/${activityId}/insights`,
    managerLearningActivityInsightsHref: (contentURN?: string, activityId?: number) =>
      `/management/analyze/content/${contentURN}/activity/${activityId}/insights`,
  };
};

export const useLearningContentNavigate = (): {
  navigate: NavigateFunction;

  navigateToAdminJourneyLearningInsights: (journeyId: number, contentURN?: string) => void;
  navigateToAdminLearningInsights: (contentURN?: string) => void;
  navigateToManagerJourneyLearningInsights: (journeyId: number, contentURN?: string) => void;
  navigateToManagerLearningInsights: (contentURN?: string) => void;
} => {
  const navigate = useNavigate();
  const href = useLearningContentHref();

  return {
    navigate,

    navigateToAdminJourneyLearningInsights: (journeyId: number, contentURN?: string) =>
      navigate(href.adminJourneyLearningInsightsHref(journeyId, contentURN)),

    navigateToAdminLearningInsights: (contentURN?: string) => navigate(href.adminLearningInsightsHref(contentURN)),

    navigateToManagerJourneyLearningInsights: (journeyId: number, contentURN?: string) =>
      navigate(href.managerJourneyLearningInsightsHref(journeyId, contentURN)),

    navigateToManagerLearningInsights: (contentURN?: string) => navigate(href.managerLearningInsightsHref(contentURN)),
  };
};

export const useLearningContentInsightsNavigateWithContext = (contentURN: string): [(contentURN: string) => void, string] => {
  const isManagementContext = useIsManagementContext();
  const href = useLearningContentHref();
  const { journeyId } = useParams<{ journeyId: string }>();
  const navigate = useNavigate();

  const getNavigationHref = (contentURN: string) => {
    if (isManagementContext && journeyId) {
      return href.managerJourneyLearningInsightsHref(Number(journeyId), contentURN);
    }
    if (isManagementContext && !journeyId) {
      return href.managerLearningInsightsHref(contentURN);
    }
    if (!isManagementContext && journeyId) {
      return href.adminJourneyLearningInsightsHref(Number(journeyId), contentURN);
    }

    return href.adminLearningInsightsHref(contentURN);
  };

  const navigationHref = getNavigationHref(contentURN);

  return [() => navigate(navigationHref), navigationHref];
};

type UseLearningContentActivityInsightsNavigateWithContextHook = (
  contentURN: string,
  activityId: number
) => [(contentURN: string) => void, string];

export const useLearningContentActivityInsightsNavigateWithContext: UseLearningContentActivityInsightsNavigateWithContextHook = (
  contentURN: string,
  activityId: number
): [(contentURN: string) => void, string] => {
  const isManagementContext = useIsManagementContext();
  const href = useLearningContentHref();
  const { journeyId } = useParams<{ journeyId: string }>();
  const navigate = useNavigate();

  const getNavigationHref = (contentURN: string, activityId: number) => {
    if (isManagementContext && journeyId) {
      return href.managerJourneyLearningActivityInsightsHref(Number(journeyId), contentURN, activityId);
    }
    if (isManagementContext && !journeyId) {
      return href.managerLearningInsightsHref(contentURN);
    }
    if (!isManagementContext && journeyId) {
      return href.adminJourneyLearningActivityInsightsHref(Number(journeyId), contentURN, activityId);
    }

    return href.adminLearningActivityInsightsHref(contentURN, activityId);
  };

  const navigationHref = getNavigationHref(contentURN, activityId);

  return [() => navigate(navigationHref), navigationHref];
};
